import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  return (
    <div className="homecontainer">
      <Link to='/contact'>
        <button className="contact-button">Contact me</button>
      </Link>
      <div className="header">
        <h1>Evan Jarvis</h1>
        <p className="subtext">This site is currently under contruction.</p>
        <p className="subtext2">Coming soon!</p>
      </div>
    </div>
  );
};

export default Home;
