import React from "react";
import "./Contact.css";

const Contact = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "1cf6e440-f116-4942-8ec0-7ea4ae97e583");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="contact-container">
      <div className="form-div">
        <form onSubmit={onSubmit}>
          <div className="border-div">
            <h2>Contact</h2>
            <div className="input-box">
              <label className="text-label">Full Name</label>
              <input
                type="text"
                className="field"
                placeholder="Enter your name"
                name="name"
                required
              />
            </div>
            <div className="input-box">
              <label className="text-label">Email Address</label>
              <input
                type="email"
                className="field"
                placeholder="Enter your email address"
                name="email"
                required
              />
            </div>
            <div className="message-box">
              <label>Your message</label>
              <textarea
                name="message"
                placeholder="Enter your message"
                required
              />
            </div>
            <div className="sumbit-button-div">
              <button type="submit" className="submit-button">
                Send message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
